import { SVGProps } from 'react'

export function LogoWithText(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '195'}
      height={props.height || '36'}
      viewBox="0 0 195 36"
      fill="currentColor"
    >
      <path d="M17.7066 8.59859L3.07718 29.7332C1.37521 28.5501 0.949032 26.2142 2.12356 24.5063L17.7066 1.84717V8.59859Z" />
      <path d="M12.9868 23.5784C11.1855 22.6402 10.1723 21.652 9.19656 20.8765L7.02002 24.0287C7.82059 24.6041 8.63366 25.3797 11.1104 26.7681C13.5114 28.1141 16.0139 28.4944 17.6401 28.8321V25.0419C15.8388 24.7042 14.788 24.5165 12.9868 23.5784Z" />
      <path d="M26.043 23.5408C27.8443 22.6026 28.8575 21.6144 29.8332 20.8389L32.0098 23.9911C31.2092 24.5665 30.3961 25.3421 27.9194 26.7305C25.5184 28.0765 23.0159 28.4826 21.3897 28.8203V25.0418C23.191 24.7041 24.2418 24.4789 26.043 23.5408Z" />
      <path d="M21.4004 8.59859L35.9502 29.7332C37.6515 28.5506 38.0761 26.2148 36.8999 24.509L21.273 1.84717L21.4004 8.59859Z" />
      <path d="M21.4007 2.01569V34.0928C21.4007 35.1461 20.5468 36 19.4934 36C18.4373 36 17.5823 35.1418 17.5862 34.0857L17.7065 1.84709C18.7479 0.84042 20.4185 0.905623 21.3783 1.99039L21.4007 2.01569Z" />
      <path d="M186.244 30.2507C180.886 30.2507 177.215 26.4144 177.215 21.0569C177.215 15.2694 181.019 11.6978 185.913 11.6978C190.874 11.6978 194.181 15.1702 194.28 20.8585V21.7844H181.118C181.382 24.8601 183.3 26.9435 186.277 26.9435C188.294 26.9435 189.815 25.9514 190.411 24.1986H194.214C193.321 27.9688 190.378 30.2507 186.244 30.2507ZM181.283 19.0395H190.245C189.749 16.5261 188.195 15.0049 185.913 15.0049C183.598 15.0049 181.878 16.5261 181.283 19.0395Z" />
      <path d="M174.403 29.7545H170.5V4.95117H171.061C172.907 4.95117 174.403 6.44753 174.403 8.29339V29.7545Z" />
      <path d="M159.755 30.2506C156.977 30.2506 154.662 28.8616 153.57 26.6458L153.207 29.7545H150.065V4.95117H150.625C152.471 4.95117 153.967 6.44753 153.967 8.29339V14.5087C155.224 12.789 157.308 11.6977 159.788 11.6977C164.418 11.6977 167.692 15.4678 167.692 20.8915C167.692 26.4143 164.418 30.2506 159.755 30.2506ZM158.895 26.9435C161.739 26.9435 163.69 24.4631 163.69 20.8915C163.69 17.419 161.739 15.0048 158.895 15.0048C156.018 15.0048 153.967 17.3859 153.967 20.7922C153.967 24.397 156.018 26.9435 158.895 26.9435Z" />
      <path d="M137.58 30.2507C132.851 30.2507 129.444 26.4144 129.444 20.8915C129.444 15.4679 132.785 11.6978 137.448 11.6978C140.192 11.6978 142.474 12.9875 143.599 14.9718L143.929 12.2269H147.071V29.7546H143.202V27.3735C142.078 29.1593 140.093 30.2507 137.58 30.2507ZM138.307 26.9435C141.185 26.9435 143.202 24.4301 143.202 20.8585C143.202 17.386 141.218 14.9718 138.374 14.9718C135.463 14.9718 133.446 17.4191 133.446 20.9246C133.446 24.4632 135.43 26.9435 138.307 26.9435Z" />
      <path d="M126.936 29.7545H124.688C120.884 29.7545 118.834 27.8694 118.834 24.3969V15.5339H115.758V12.2268H118.834V5.04004H119.394C121.24 5.04004 122.736 6.5364 122.736 8.38226V12.2268H127.267V15.5339H122.736V24.1324C122.736 25.6206 123.497 26.4473 124.952 26.4473H126.936V29.7545Z" />
      <path d="M103.561 30.2507C98.8321 30.2507 95.4258 26.4144 95.4258 20.8915C95.4258 15.4679 98.766 11.6978 103.429 11.6978C106.174 11.6978 108.456 12.9875 109.58 14.9718L109.911 12.2269H113.053V29.7546H109.183V27.3735C108.059 29.1593 106.075 30.2507 103.561 30.2507ZM104.289 26.9435C107.166 26.9435 109.183 24.4301 109.183 20.8585C109.183 17.386 107.199 14.9718 104.355 14.9718C101.445 14.9718 99.4274 17.4191 99.4274 20.9246C99.4274 24.4632 101.412 26.9435 104.289 26.9435Z" />
      <path d="M84.7738 30.2506C81.9959 30.2506 79.6809 28.8616 78.5895 26.6458L78.2257 29.7545H75.084V4.95117H75.6442C77.49 4.95117 78.9864 6.44753 78.9864 8.29339V14.5087C80.2431 12.789 82.3266 11.6977 84.8069 11.6977C89.4369 11.6977 92.7109 15.4678 92.7109 20.8915C92.7109 26.4143 89.4369 30.2506 84.7738 30.2506ZM83.914 26.9435C86.7581 26.9435 88.7093 24.4631 88.7093 20.8915C88.7093 17.419 86.7581 15.0048 83.914 15.0048C81.0368 15.0048 78.9864 17.3859 78.9864 20.7922C78.9864 24.397 81.0368 26.9435 83.914 26.9435Z" />
      <path d="M53.9368 29.7543H49.8359L59.2943 4.80322H60.9583C62.3457 4.80322 63.5889 5.66038 64.0822 6.95713L72.7542 29.7543H68.6534L66.3054 24.033H56.2848L53.9368 29.7543ZM61.3116 10.2295L57.7069 20.5274H64.8833L61.3116 10.2295Z" />
    </svg>
  )
}
